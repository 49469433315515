import './App.css';

function App() {
  return (
    <div className="App">
      <section class="hero">
        <div class="hero-content"></div>
      </section>
    </div>
  );
}

export default App;
